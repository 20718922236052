
import { defineComponent } from 'vue'
import DemoModal from '@/components/views/ui/modals/DemoModal.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
    DemoModal,
  },
})
